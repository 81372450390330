<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "nǐ",
          hanzi: "铅笔",
          english: "you",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-17-qianbi-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "hǎo",
          hanzi: "床",
          english: "good",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-17-chuang-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "nǐ hǎo",
          hanzi: "上（边）",
          english: "Hello",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-17-shangbian-bubble.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "wǒ",
          hanzi: "桌子",
          english: "I; me",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-17-zhuozi-bubble.svg`),
        },
         {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "wǒ",
          hanzi: "眼镜",
          english: "I; me",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-17-yanjing-bubble.svg`),
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
