<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage :singList="singList" :songInfo="songInfo" :timeStampList="timeStampList" :isReviewPage="isReviewPage"></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";


export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-81.mp3"),
      },
      timeStampList: [6.2, 9.3, 12.6, 15.7, 18.9, 22.1, 25.3, 28.7],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì Dōngdōng.",
            chineseWords: "铅笔，铅笔，",
          },
          {
            id: 2,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì Xiǎo Ài.",
            chineseWords: "铅笔在床上吗？",
          },
          {
            id: 3,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì BB",
            chineseWords: "铅笔，铅笔，",
          },
          {
            id: 4,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "铅笔在桌子上。",
          },
          {
            id: 5,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "眼镜，眼镜，",
          },
          {
            id: 6,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "眼镜在桌子上吗？",
          },
          {
            id: 7,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "眼镜，眼镜，",
          },
          {
            id: 8,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "眼镜在鼻子上！",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
