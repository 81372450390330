<template>
  <div class="lesson-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="3" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";
export default {
  data() {
    return {
      positionStr: "lesson",
      pageInfoObj: {
        pinyin: "Wǒ de péngyou",
        hanzi: "日常用品",
        english: "My friends",
        sound: require("@/assets/audio/chapter_1/chapter-01-zaixuexiao.mp3"),
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.lesson-container {
  width: 100%;
  height: 100%;
}
</style>