<template>
  <div class="game-container">
    <SpeakingSentence
      :titleInfo="titleInfo"
      :buttonList="buttonList"
      :gameList="gameList"
      @initGame="initGame"
      @showSentence="showSentence"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      titleInfo: {
        hanzi: "他叫……",
        pinyin: "Tā jiào ……",
      },
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-2/background-1-dongdong.svg`),
          pinyin: "Tā jiào Dōngdōng",
          hanzi: "他叫冬冬。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-2/background-2-tutu.svg`),
          pinyin: "Tā jiào Tútú",
          hanzi: "他叫图图。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-2/background-3-xiaotian.svg`),
          pinyin: "Tā jiào Xiǎo Tiān",
          hanzi: "他叫小天。",
          isShowSentence: false,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-17-2/menu-1-dongdong.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-17-2/menu-2-tutu.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-17-2/menu-3-xiaotian.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
    };
  },
  components: {
    SpeakingSentence,
  },
  methods: {
    initGame() {
      this.gameList.forEach((item) => {
        item.isShowSentence = false;
      });
    },
    showSentence(index) {
      this.gameList[index - 1].isShowSentence = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>