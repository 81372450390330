<template>
  <div class="summary-container">
    <SummaryPage
      :summaryTitle="summaryTitle"
      :summaryList="summaryList"
      :lessonNum="9"
    />
  </div>
</template>

<script>
import SummaryPage from "@/components/Course/CoursePage/SummaryPage";
export default {
  data() {
    return {
      summaryTitle: {
        pinyin: "Tā / Tā shì shéi ？",
        hanzi: "他/她是谁？",
      },
      summaryList: [
        {
          pinyin: "shéi",
          hanzi: "谁",
          imgUrl: require("@/assets/img/12-Vocabulary/lesson-17-shei.svg"),
          bgColor: 1,
        },
        {
          pinyin: "tā",
          hanzi: "他",
          imgUrl: require("@/assets/img/12-Vocabulary/lesson-17-ta-he.svg"),
          bgColor: 2,
        },
        {
          pinyin: "jiào",
          hanzi: "叫",
          imgUrl: require("@/assets/img/12-Vocabulary/lesson-17-jiao.svg"),
          bgColor: 1,
        },
        {
          pinyin: "tā",
          hanzi: "她",
          imgUrl: require("@/assets/img/12-Vocabulary/lesson-17-ta-she.svg"),
          bgColor: 3,
        },

      ],
    };
  },
  components: {
    SummaryPage,
  },
};
</script>

<style lang="scss" scoped>
.summary-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>