<template>
  <div class="game-container">
    <ScratchPicGame
      :gameList="gameList"
      :starNum="4"
      :gameInfo="gameInfo"
      :buttonList="buttonList"
    />
  </div>
</template>

<script>
import ScratchPicGame from "@/components/Course/GamePage/ScratchPicGame";
export default {
  data() {
    return {
      gameInfo: {
        titlePinyin: "Tā / Tā  shì shéi",
        titleHanzi: "他/她是谁?",
      },
      gameList: [
        {
          // bgImg: require("@/assets/img/16-GAMES/G27-click-and-erase/image-1-1.svg"),
          // answerImg: require("@/assets/img/16-GAMES/G27-click-and-erase/image-1-2.svg"),
        },
        {
          // bgImg: require("@/assets/img/16-GAMES/G27-click-and-erase/image-2-1.svg"),
          // answerImg: require("@/assets/img/16-GAMES/G27-click-and-erase/image-2-2.svg"),
        },
        {
          // bgImg: require("@/assets/img/16-GAMES/G27-click-and-erase/image-3-1.svg"),
          // answerImg: require("@/assets/img/16-GAMES/G27-click-and-erase/image-3-2.svg"),
        },
        {
          // bgImg: require("@/assets/img/16-GAMES/G27-click-and-erase/image-4-1.svg"),
          // answerImg: require("@/assets/img/16-GAMES/G27-click-and-erase/image-4-2.svg"),
        },
      ],
      buttonList: [
        {
          // image: require("@/assets/img/16-GAMES/G27-click-and-erase/question-yellow.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          // image: require("@/assets/img/16-GAMES/G27-click-and-erase/question-white.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          // image: require("@/assets/img/16-GAMES/G27-click-and-erase/question-red.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          // image: require("@/assets/img/16-GAMES/G27-click-and-erase/question-yellow.svg"),
          isCircle: true,
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
    };
  },
  components: {
    ScratchPicGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











